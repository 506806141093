import Alert from '../../scripts/alert'
import { convertTZ, UTCString } from '../../scripts/date'
import Slot from '../../scripts/slot.js'
import CMS from '../../service/cms/service.js'

const deleteSlot = (context, {slot}) => {
    return new Promise((resolve) => {
        context.commit('delete-slot', {slot})
        resolve()
    })
}

const addSlot = (context, payload) => {
    context.commit('add-slot', payload)
}

const createSlot = ({getters, commit}, {calendarUid, freePeriod, slotData}) => {

    if (slotData){
        return createSlots({getters, commit}, {calendarUid, slotsData: slotData})
    }
    
    let browserTimezone = getters.browserTimezone
    let calendarTimezone = getters.calendarTimezone

    let convertedDate = convertTZ(freePeriod.start.date, calendarTimezone, browserTimezone)
    
    let hosts = getters.calendarHosts
    let ratings = getters.calendarRatings
    let priceLevels = getters.calendarPriceLevels

    slotData = {
        startAt: UTCString(convertedDate),
        dur: freePeriod.duration,
        timezoneId: calendarTimezone.timezoneId,
    }

    if (hosts && hosts.length == 1)
        slotData["hostId"] = hosts[0].id
    if (ratings && ratings.length == 1)
        slotData["ratingId"] = ratings[0].id
    if (priceLevels && priceLevels.length == 1)
        slotData["levelId"] = priceLevels[0].id
    
    return createSlots({getters, commit}, {calendarUid, slotsData: slotData})
}

function createSlots ({getters, commit}, {calendarUid, slotsData})
{
    let browserTimezone = getters.browserTimezone
    let calendarTimezone = getters.calendarTimezone
	let userId = getters.userId

	if (!slotsData["hostId"]) {
		slotsData["hostId"] = userId
	}

    return CMS.slots.upload(calendarUid, slotsData)
    .then((newSlots) => {          
        if (newSlots.err){
            throw newSlots.err
        }   

        let slots = newSlots.map(slotInfo => {
            let date = new Date(slotInfo.startAt)
            date = convertTZ(date, browserTimezone, calendarTimezone)
            return new Slot(date, slotInfo.dur, slotInfo)
        }) 
        commit('add-slot', {slots})
        return slots
    })
    .catch( (error) => console.error(error))
}

const updateSlotTime = ({commit, getters}, {slotIndex, date, isAfterPut=false}) => {
    console.log(`update-slot: ${slotIndex} : start date ${date}`)

    let slot = getters.getSlotByIndex(slotIndex)
    
    let time = slot.state.isBusy? slot.state.nearest.place : date
    
    if (!isAfterPut){
        let browserTimezone = getters.browserTimezone
        let calendarTimezone = getters.calendarTimezone
        
        date = convertTZ(date, calendarTimezone, browserTimezone)
        
        let calendarUid = getters.calendarUid
        let slotId = slotIndex
        CMS.slots.patch(calendarUid, slotId, {startAt: UTCString(date)}).then(slotData => {
            if (slotData.err){
                throw slotData.err
            }
            commit('update-slot-time', {slotIndex, time})            
        })
    } else {
        commit('update-slot-time', {slotIndex, time})
    }
    
    slot.clearState()
}

const updateMultiSlotTime = ({commit, getters}, {slotDateArray, isAfterPut=false}) => {
    let browserTimezone = getters.browserTimezone
    let calendarTimezone = getters.calendarTimezone

    let calendarUid = getters.calendarUid
    let submitData = slotDateArray.map(slotDate => {
        console.log(`slotDate: ${slotDate.slot.index} 
            ${slotDate.slot.period.duration} : ${slotDate.date.toLocaleTimeString()} `);
        slotDate.date = convertTZ(slotDate.date, calendarTimezone, browserTimezone)
        return {slotId: slotDate.slot.index, startAt: UTCString(slotDate.date)}   
    })

    return CMS.slots.multiPatch(calendarUid, submitData)
        .then(updatedSlotData => {
            if (updatedSlotData.err){
                throw updatedSlotData.err
            }
            slotDateArray.forEach((slotDate) => {
                commit('update-slot-time', {
                    slotIndex : slotDate.slot.index, 
                    time : slotDate.date
                })
                slotDate.slot.clearState()           
            })
        })
}

const updateSlotDate = ({commit, getters},{slotIndex, futureColumnNumber, date, isAfterPut=false}) => {

    return new Promise((resolve, reject) => {
        try{
            let slot = getters.getSlotByIndex(slotIndex)

            if (!date){
                let oldDate = slot.period.start.date
                date = new Date(oldDate.getTime() + 86400 * futureColumnNumber * 1000)
            }
            date = slot.state.isBusy? slot.state.nearest.place : date
            console.log(`busy : ${slot.state.isBusy} : date ${date}`)
            commit('update-slot-date', {slotIndex, date})

            if (!isAfterPut){
                let browserTimezone = getters.browserTimezone
                let calendarTimezone = getters.calendarTimezone
                
                date = convertTZ(date, calendarTimezone, browserTimezone)
                
                let calendarUid = getters.calendarUid
                let slotId = slotIndex
                CMS.slots.patch(calendarUid, slotId, {startAt: UTCString(date)}).then(slotData => {
                    if (slotData.err){
                        throw slotData.err
                    }
                })
            }   
            
            slot.clearState()
            resolve({newDate:date})

        }   
        catch (err){
            console.error(err)
            reject(err)
        }
    })
}

const updateSlotStyles = (context, payload) => {
    context.commit('update-slot-state', payload)
}

const updateSlots = (store, payload) => {
    payload["mutationName"] = 'update-slots'
    return updateSlotsHandler(store, payload)
}

const updateComparableSlots = (store, payload) => {
    store.commit('setup-compare-calendar', payload.uid)
    payload["mutationName"] = 'update-compare-slots'
    return updateSlotsHandler(store, payload)
}

const updateSlotsHandler = ({commit, getters}, {uid, from, to, mutationName}) => {
    return CMS.slots.getAll(uid, from, to).then(data => {
        if (data.err){
            throw data.err
        }


        let slotArray = []
        if(data.slots == null){
            return
        }
        let calendarTimezone = getters.calendarTimezone
        let browserTimezone = getters.browserTimezone

        data.slots.forEach(slotInfo => {
            
            // date in local timezone 
            let date = new Date(slotInfo.startAt)
            let dateInCalendarTZ = convertTZ(date, browserTimezone, calendarTimezone)
            let dur = slotInfo.dur

            delete slotInfo.startAt
            delete slotInfo.dur
            
            slotArray.push({dateInCalendarTZ, dur, slotInfo})
        })
        commit(mutationName, {slotsData: slotArray})
    }).catch((error)=>{console.error(error)})
}

const clearSlots = (context, _) => context.commit('clear-slots')
const clearCompareSlots = (context, _) => context.commit('clear-compare-slots')

const updateCopiedSlot = (context, slot) => {
    context.commit('update-copied-slot', slot)
}

export {
    addSlot,
    createSlot,
    deleteSlot,
    createSlots,

    updateMultiSlotTime,
    updateSlotTime,
    updateSlotDate,

    updateSlotStyles,
    updateComparableSlots,

    updateSlots,
    clearSlots,
    clearCompareSlots,
    updateCopiedSlot,
}