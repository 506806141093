import Vue from 'vue'
import table from './calendar-table-store'

const getDefaultState = () => {
    return {
		currentCalendarUid: "",
		calendars: {},
		pageActivity: null, // provide single activity on the game
    }
}

export default {
    state : getDefaultState(),    
    modules: {
        table,
    },
    mutations: {
		'add-calendar': (state, calendarInstance) => {
			Vue.set(state.calendars, calendarInstance.uid, calendarInstance)
		},
		'set-current-calendar': (state, calendarUid) => {
			state.currentCalendarUid = calendarUid
		},
		'set-page-activity': (state, activity) => {
			if (state.activity)
				return 
			state.pageActivity = activity
		},
    },
    getters: {
		getCalendarByUid(state) {
			return (uid) => {
				return state.calendars[uid]
			}
		},
		currentCalendar(state) {
			return () => {
				return state.calendars[state.currentCalendarUid]
			} 
		},
		calendarTimezone(state, getters) {
			const currentCalendar = state.calendars[state.currentCalendarUid]
			if (!currentCalendar)
				return undefined

			const timezone = getters.getTimezoneByID(currentCalendar.timezoneId)

			return timezone
		},
		calendarSlotCollection(state) {
			const currentCalendar = state.calendars[state.currentCalendarUid]
			return currentCalendar.slotCollection
		},
		calendarSlotsByDate(state) {
			return (date, params={calendarUid: null, sortByTime: false, withQuestionSlots: false}) => {
				const currentCalendar = params.calendarUid ?
					state.calendars[params.calendarUid] :
					state.calendars[state.currentCalendarUid]
				currentCalendar.slotCollection.loadingCount
				return currentCalendar.slotCollection.getByDate(date, {
					sortByTime: params.sortByTime, 
					withQuestionSlots: params.withQuestionSlots
				})
			}
		},
		pageActivity(state) {
			return state.pageActivity
		}
    },	
    actions: {
        'add-calendar': ({commit}, calendarInstance) => {
			commit('add-calendar', calendarInstance)
		},
		'set-current-calendar': ({commit}, calendarUid) => {
			commit('set-current-calendar', calendarUid)
		},
		'set-page-activity': ({commit}, activity) => {
			commit('set-page-activity', activity)
		}
    }
}