<template>
	<BurgerMenu :show="show" :closer="closer" name="calendar" :height="menuHeight">
		<div class="menu-items">
			<BurgerMenuItem
				v-for="(item, index) in menuItems"
				:key="'menu-item-' + index"

				:title="item.title"
				:titleColor="item.titleColor"
				:titleStyle="item.titleStyle"

				:icon="item.icon"
				:iconColor="item.iconColor"
				:iconSize="item.iconSize"

				:usage="item.usage"/>
		</div>
		<div class="project-version">
			Version: {{ version() }}
		</div>
	</BurgerMenu>
</template>

<script>
import { ADMIN_MEMBERSHIP_LEVEL, OWNER_MEMBERSHIP_LEVEL } from '../../scripts/membershipLevels';
import BurgerMenu from '../burger-menu/BurgerMenu.vue';
import BurgerMenuItem from '../burger-menu/BurgerMenuItem.vue';

import router from '../../router';
import AuthService from '../../service/auth-service';

export default {
    name: 'CalendarBurgerMenu',
    components: {
        BurgerMenu,
        BurgerMenuItem
    },
    props: {
        show: Boolean,
        closer: Function,
    },
	computed:{
		menuHeight(){
			const menuItemsCount = this.menuItems.length
			const menuItemHeight = 30
			const menuItemGap = 12
			const defaultMenuHeight = 104

			return defaultMenuHeight
				+ menuItemsCount * menuItemHeight 
				+ (menuItemsCount - 1) * menuItemGap
		},

		menuItems(){
			
			let items = [
                {
					title: "Account settings", 
					icon: "mdi:tooltip-user",
					usage: () => router.push({
						name: "account-settings",
					}),
				},
				{
                    title: "Exit",
                    titleColor: "red",
                    icon: "bx:exit",
                    iconColor: "red",
                    usage: this.sighOut,
                }
                // calendarSettings if calendar is owner's or admin's
            ]

			const calendar = this.$store.getters.currentCalendar()
			if (!calendar)
				return items
            
            let calendarSettings = {
                title : `Calendar settings "${calendar.title}"`,
                icon : "fluent:settings-32-regular",
                usage : () => router.push({
                    name: "calendar-settings",
                })
            }

            let calendarAttenders = {
                title: "They pay me",
                icon: "ph:users",
                usage: () => router.push({
                    name: "calendar-settings-attenders-menu",
                }),
            }

			let calendarHosts = {
				title: "I pay them",
				icon: "ph:users",
				usage: () => router.push({
					name: "calendar-settings-host-menu",
				}),
			}
        

            
            if (calendar.isEditable){
                items.splice(1, 0, calendarSettings)
                items.splice(1, 0, calendarAttenders)
                items.splice(1, 0, calendarHosts)
            }

            return items
        }
	},
    methods: {
		version(){
			return process.env.VUE_APP_GIT_HASH
		},

        async sighOut(){
            AuthService.signOut()
            this.$store.dispatch("delete-user-data")
        },
    },
};
</script>

<style scoped>
.menu-items{
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.project-version{
	padding: 0 12px;
}
</style>