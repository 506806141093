import Vue from 'vue'
import { 
    addSlot, 
    clearSlots, 
    createSlot, 
    deleteSlot, 
    updateSlotDate, 
    updateSlots, 
    updateSlotStyles, 
    updateSlotTime, 
    clearCompareSlots,
    updateComparableSlots, 
    updateMultiSlotTime,
    updateCopiedSlot,
    createSlots
} from './calendar-slots-action'
import Slot from '../../scripts/slot'
import { isEqualDate } from '../../scripts/date'

const getDefaultState = () => {
    return {
        slots: {},
        copiedSlot: null,
        compareSlots: {},
        compareCalendarUid: "",
    }
}

export default {
    state: getDefaultState(),
    mutations: {
        'update-slots' : (state, {slotsData}) => {
            let currentSlotsIndices = Object.keys(state.slots)
            slotsData.forEach((slot) => {
                let {dateInCalendarTZ, dur, slotInfo} = slot

                if (currentSlotsIndices.includes(`${slot.slotInfo.id}`)){
                    state.slots[slot.slotInfo.id].update(dateInCalendarTZ, dur, slotInfo)
                } else {
                    Vue.set(state.slots, slot.slotInfo.id, new Slot(dateInCalendarTZ, dur, slotInfo))
                }
            })
            
            let allCardNames = Object.values(state.slots).map(slot => `new-card-${slot.index}`)
            // bus.offUselessSimilarTypes(allCardNames, 'new-card-')
        },
        'update-compare-slots' : (state, {slotsData}) => {
            let currentSlotsIndices = Object.keys(state.compareSlots)

            slotsData.forEach((slot) => {
                let {dateInCalendarTZ, dur, slotInfo} = slot

                if (currentSlotsIndices.includes(`${slot.slotInfo.id}`)){
                    state.compareSlots[slot.slotInfo.id].update(dateInCalendarTZ, dur, slotInfo)
                } else {
                    Vue.set(state.compareSlots, slot.slotInfo.id, new Slot(dateInCalendarTZ, dur, slotInfo))
                }
            })
            
            let allCardNames = Object.values(state.compareSlots).map(slot => `comparable-card-${slot.index}`)
            // bus.offUselessSimilarTypes(allCardNames, 'comparable-card-')
        },

        'setup-compare-calendar' : (state, calendarUid) => {
            state.compareCalendarUid = calendarUid
        },

        'add-slot' : (state, {slots}) => {
            slots.forEach((slot) => {
                Vue.set(state.slots, slot.index, slot)
            })
        },
        'update-slot-time' : (state, {slotIndex, time}) => {
            time = new Date(time.getTime())
            state.slots[slotIndex].period.update(time)
            state.slots[slotIndex].cardPeriod.update(time)
        },
        'update-slot-date' : (state, {slotIndex, date}) => {
            date = new Date(date.getTime())
            state.slots[slotIndex].period.update(date)
            state.slots[slotIndex].cardPeriod.update(date)
        },
        'update-slot-state' : ({slots}, {index, isChangeColumn}) => {
            if (!index) return

            if (isChangeColumn !== undefined){
                slots[index].styles.isChangeColumn = isChangeColumn
            }  
        },
        'update-copied-slot' : (state, slot) => {
            state.copiedSlot = slot
        },
        'delete-slot' : (state, {slot}) => {
            Vue.delete(state.slots, slot.index)
        },
        'clear-slots' : (state, _) => {
            Vue.set(state, 'slots', {})
        },
        'clear-compare-slots' : (state, _) => {
            Vue.set(state, 'compareSlots', {})
        },
    },
    getters: {
        // calendarSlotsByDate: (state) => (date) => {
        //     let result = {}
        //     Object.values(state.slots)
        //         .filter(slot => isEqualDate(slot.period.start.date, date))
        //         .forEach(slot => result[slot.index] = slot)
        //     if (state.copiedSlot && isEqualDate(date, state.copiedSlot.period.start.date))
        //         result[-1] = state.copiedSlot
        //     return result
        // },
        getSlotByIndex: (state) => (slotIndex) => {
            return state.slots[slotIndex]
        },
        compareSlotsByDate: (state) => (date) => {
            let result = {}
            let isEqualDate = (slot, date) => {
                return slot.date() == date.getDate() &&
                    slot.month() == date.getMonth() &&
                    slot.year() == date.getFullYear()
            }
            let compareSlots = Object.values(state.compareSlots).filter(slot => isEqualDate(slot, date))
            compareSlots.forEach(slot => result[slot.index] = slot)
            return result
        },
        compareCalendarUid: (state) => {
            return state.compareCalendarUid
        },
        copiedSlot: (state) => {
            return state.copiedSlot
        },
    },
    actions: {
        'add-slot' : addSlot,
        'create-slot' : createSlot,
        'delete-slot' : deleteSlot,
        'create-slots' : createSlots,
        'update-multi-slot-time' : updateMultiSlotTime,
        'update-slot-time' : updateSlotTime,
        'update-slot-date' : updateSlotDate,
        'update-slot-styles' : updateSlotStyles,

        'update-slots' : updateSlots,
        'clear-slots' : clearSlots,

        'update-compare-slots': updateComparableSlots,
        'clear-compare-slots': clearCompareSlots,

        'update-copied-slot': updateCopiedSlot,
    }
}